import React from "react";

export default function AppWrap() {
  return (
    <div>
      <Card>
        <p>안녕하세여</p>
      </Card>
    </div>
  );
}

function Card({ children }) {
  return (
    <div
      style={{
        backgroundColor: "wheat",
        width: "200px",
        height: "200px",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {children}
    </div>
  );
}
